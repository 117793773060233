import createAxiosInstance from "../utils/axios.config";

export async function loader({ params }) {
  const client = createAxiosInstance()
  try {
    const result = await client.get(`campaign/${params.id}`);
    const campania = result.data;
    return { campania };
  } catch (error) {
  }
}
