import DashboardChart from "../components/DashboardChart/DashboardChart"
import CampaignsLaunchedGrid from "../components/Grids/CampaignsLaunchedGrid"
import LaunchedCampaigns from "../components/Table/LaunchedCampaigns"


function Dashboard({ setPageCallBack }) {
    return (
        <>
            
            <div className="row" >
                <div className="col-md-12">
                    <DashboardChart />
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <LaunchedCampaigns />
                </div>
            </div>
        </>
    )
}

export default Dashboard