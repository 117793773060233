import { Popconfirm, Table } from "antd";
import React, { useEffect, useState } from "react";
import createAxiosInstance from "../../utils/axios.config";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";

export default function CampaignsTable() {
  const client = createAxiosInstance();
  const [dataSource, setDataSource] = useState();
  const navigate = useNavigate();

  const handleDelete = (key) => {
    const newData = dataSource.filter((item) => item.key !== key);
    setDataSource(newData);
    try {
      client({
        url: "/campaign",
        method: "DELETE",
        data: {
          id_campania: key,
        },
      })
        .then((res) => {
          swal("Registro borrado correctamente", "", "success");
        })
        .catch((e) => {
          swal(
            "Error",
            "Ocurrió un error, por favor inténtelo mas tarde",
            "error"
          );
        });
    } catch (error) {
      swal(
        "Error al crear el contacto",
        "Ocurrió un error, por favor inténtelo más tarde",
        "error"
      );
    }
  };

  const columns = [
    {
      title: "Nombre de la campaña",
      dataIndex: "nombre_campania",
      editable: true,
    },
    {
      title: "Nombre del IVR",
      dataIndex: "nombre_ivr",
    },
    {
      title: "Número del IVR",
      dataIndex: "numero_ivr",
    },
    {
      title: "fecha de creación",
      dataIndex: "fecha_creacion",
    },
    {
      title: "Fecha de ultimo lanzamiento",
      dataIndex: "fecha_ultimo_lanzamiento",
    },
    {
      title: "Acción",
      dataIndex: "operation",
      render: (_, record) =>
        dataSource.length >= 1 ? (
          <>
            <button
              type="button"
              className="btn btn-warning mr-3"
              onClick={() => {
                navigate(`/edit-campaign/${record.key}`);
              }}
            >
              Editar
            </button>

            <button
              type="button"
              className="btn btn-success mr-3"
              onClick={() => {
                navigate(`/launch-campaign/${record.key}`);
              }}
            >
              Lanzar
            </button>

            <Popconfirm
              title="¿Desea eliminar esta campaña?"
              onConfirm={() => handleDelete(record.key)}
              okText="Sí"
              cancelText="No"
            >
              <button type="button" className="btn btn-danger">
                Eliminar
              </button>
            </Popconfirm>
          </>
        ) : null,
    },
  ];
  const [loading, setLoading] = useState(false);
  function formaterDate(date) {
    const dateObj = new Date(date);
    if (!date) return "";
    const year = dateObj.getUTCFullYear();
    const month = String(dateObj.getUTCMonth() + 1).padStart(2, "0");
    const day = String(dateObj.getUTCDate()).padStart(2, "0");
    const hours = String(dateObj.getUTCHours()).padStart(2, "0");
    const minutes = String(dateObj.getUTCMinutes()).padStart(2, "0");
    const seconds = String(dateObj.getUTCSeconds()).padStart(2, "0");

    let formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    if (formattedDate === "NaN-NaN-NaN") formattedDate = "";

    return formattedDate;
  }
  useEffect(() => {
    setLoading(true);
    client
      .get("campaign")
      .then((response) => {
        response.data.map((item) => {
          item.key = item.id_campania;

          item.fecha_creacion = formaterDate(item.fecha_creacion);
          item.fecha_ultimo_lanzamiento = formaterDate(
            item.fecha_ultimo_lanzamiento
          );
        });
        setDataSource(response.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);

  return (
    <div>
      <Table loading={loading} dataSource={dataSource} columns={columns} />
    </div>
  );
}
