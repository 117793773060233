import logo from "../../images/IYD-logo_fix.png";
import "./NavSidebar.css";
import { Auth } from "aws-amplify";
import { SidebarData } from "../../data/application-data";
import NavItem from "../NavItem/NavItem";
import NavSubItems from "../NavSubItems/NavSubitems";
import { v4 as uuidv4 } from "uuid";
import { useState } from "react";
import userIcon from "../../images/icons/user-64.png";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
function NavSidebar() {
  const [showProfile, setShowProfile] = useState("");
  const [ariaExpanded, setAriaExpanded] = useState(false);
  const navigate = useNavigate();
  return (
    <>
      <nav className="topnav navbar navbar-light" style={{background:"white"}}>
        <button
          type="button"
          className="navbar-toggler text-muted mt-2 p-0 mr-3 collapseSidebar"
          onClick={() => {
            if (
              document.getElementById("body").className !==
              "vertical light collapsed"
            ) {
              document.getElementById("body").className =
                "vertical light collapsed";
            } else {
              document.getElementById("body").className = "vertical light";
            }
          }}
        >
          <i className="fe fe-menu navbar-toggler-icon"></i>
        </button>
        {/*<form className="form-inline mr-auto searchform text-muted">
          <input
            className="form-control mr-sm-2 bg-transparent border-0 pl-4 text-muted"
            type="search"
            placeholder="Type something..."
            aria-label="Search"
          />
        </form>*/}
        <ul className="nav">
          {/*<li className="nav-item">
            <a
              className="nav-link text-muted my-2"
              id="modeSwitcher"
              data-mode="light"
            >
              <i className="fe fe-sun fe-16"></i>
            </a>
      </li>*/}
          {/*<li className="nav-item nav-notif" key={uuidv4()}>
            <a
              className="nav-link text-muted my-2"
              data-toggle="modal"
              data-target=".modal-notif"
            >
              <span className="fe fe-bell fe-16"></span>
              <span className="dot dot-md bg-success"></span>
            </a>
    </li>*/}
          <li className={"nav-item dropdown " + showProfile} key={uuidv4()}>
            <a
              className="nav-link dropdown-toggle text-muted pr-0"
              id="navbarDropdownMenuLink"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded={ariaExpanded}
              onClick={() => {
                if (showProfile === "") {
                  setShowProfile("show");
                  setAriaExpanded(true);
                } else {
                  setShowProfile("");
                  setAriaExpanded(false);
                }
              }}
            >
              <span className="fe fe-user mr-2" style={{fontSize:"25px"}}>
                {/*<img
                  src={userIcon}
                  alt="..."
                  className="avatar-img rounded-circle"
            />*/}
              </span>
            </a>
            <div
              className={"dropdown-menu dropdown-menu-right " + showProfile}
              aria-labelledby="navbarDropdownMenuLink"
            >
              <a
                className="dropdown-item"
                onClick={() => {
                  navigate("/edit-profile");
                }}
              >
                Configuraciones
              </a>
              <a
                className="dropdown-item"
                onClick={ () => {
                  swal({
                    title: "Cerrar Sesión",
                    text: "¿Estas seguro que deseas cerrar sesión?",
                    icon: "warning",
                    buttons: ["Cancelar", "Cerrar Sesión"],
                  }).then(async (respuesta) => {
                    if (respuesta) {
                      await Auth.signOut()
                      sessionStorage.removeItem("token");
                      sessionStorage.removeItem("user_rol");
                      navigate("/login");
                    }
                  });
                }}
              >
                Cerrar Sesión
              </a>
            </div>
          </li>
        </ul>
      </nav>
      <aside
        className="sidebar-left border-right bg-white shadow"
        id="leftSidebar"
        data-simplebar
      >
        <a
          className="btn collapseSidebar toggle-btn d-lg-none text-muted ml-2 mt-3"
          data-toggle="toggle"
          onClick={() =>
            (document.getElementById("body").className = "vertical light")
          }
        >
          <i className="fe fe-x">
            <span className="sr-only"></span>
          </i>
        </a>
        <nav className="vertnav navbar navbar-light">
          <div className="w-100 mb-4 d-flex">
            <a
              key={uuidv4()}
              className="navbar-brand mx-auto mt-2 flex-fill text-center"
              href="/dashboard"
            >
              <img className="logo" src={logo} alt="" />
            </a>
          </div>

          {SidebarData.map((group) => {
            return (
              <ul className="navbar-nav flex-fill w-100 mb-2" key={uuidv4()}>
                <p className="text-muted nav-heading mt-4 mb-1" key={uuidv4()}>
                  <span key={uuidv4()}>{group.groupname}</span>
                </p>
                {group.items.map((item, index) => {
                  return item.subItems ? (
                    <NavSubItems
                      name={item.nameItem}
                      subItems={item.subItems.map((item, index) => {
                        return (
                          <NavItem
                            key={uuidv4()}
                            name={item.nameSubItem}
                            route={item.route}
                          />
                        );
                      })}
                      key={uuidv4()}
                    />
                  ) : (
                    <NavItem
                      name={item.nameItem}
                      icon={item.icon}
                      route={item.route}
                      key={uuidv4()}
                    />
                  );
                })}
              </ul>
            );
          })}
        </nav>
      </aside>
    </>
  );
}
export default NavSidebar;
