import { gql} from "@apollo/client";

 export const GETIVRS = gql`
  query obtener_ivrs_de_usuario($token: String!) {
    obtener_ivrs_de_usuario(token: $token) {
      estado
      data {
        id_ivr
        nombre
        descripcion
        extension_ivr
        fecha_creacion
        fecha_actualizacion
        estado
      }
      error
    }
  }
`;