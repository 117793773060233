import React, { useEffect, useState } from "react";
import logo from "../../images/nux.webp";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import { Formik } from "formik";
import swal from "sweetalert";
import RegisterSuccessfully from "./RegisterSuccessfully";
import { Auth } from "aws-amplify";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { parsePhoneNumberFromString } from "libphonenumber-js";

export default function Index() {
  const [registerSuccessfully, setRegisterSuccessfully] = useState(false);
  const [email, setEmail] = useState();
  const [cognitoUserName, setCognitoUserName] = useState("");

  return !registerSuccessfully ? (
    <Formik
      initialValues={{
        email: "",
        userName: "",
        cellNumber: "",
        password: "",
        rePassword: "",
      }}
      validate={(values) => {
        const errors = {};
        // Validación de email
        if (!values.email) {
          errors.email = "Campo requerido";
        } else if (
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
        ) {
          errors.email = "Formato de email inválido";
        }

        // Validación de nombre de usuario
        if (!values.userName) {
          errors.userName = "Campo requerido";
        } else if (!/^[A-Za-z0-9\s]+$/g.test(values.userName)) {
          errors.userName = "Nombre de usuario inválido";
        }

        // Validación de número de contacto
        if (!values.cellNumber) {
          errors.cellNumber = "Campo requerido";
        } else if (!/(4|6|7)([0-9]){7}/.test(values.cellNumber)) {
          errors.cellNumber = "Numero de celular inválido";
        }

        // Validación de contraseña
        if (!values.password) {
          errors.password = "Campo requerido";
        } else if (
          !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?^&]{8,}$/.test(
            values.password
          )
        ) {
          errors.password = "La contraseña no cumple los requisitos";
        }

        // Validación de la repetición de la contraseña
        if (!values.rePassword) {
          errors.rePassword = "Campo requerido";
        } else if (values.password !== values.rePassword) {
          errors.rePassword = "La contraseña no coincide";
        }
        return errors;
      }}
      onSubmit={async (values, { setSubmitting }) => {
        const user = await Auth.signUp({
          username: values.email,
          password: values.password,
          attributes: {
            "custom:cell_phone": values.cellNumber,
            "custom:user_name": values.userName,
          },
        });
        if (user != null) {
          setEmail(values.email);
          setCognitoUserName(user.userSub);
          setRegisterSuccessfully(true);
        }
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        setFieldValue,
        handleSubmit,
        isSubmitting,
      }) => (
        <div className="">
          <div className=" align-items-center">
            <Form
              noValidate
              onSubmit={handleSubmit}
              className="col-lg-6 col-md-8 col-10 mx-auto"
            >
              <div className="mx-auto text-center my-4">
                <a
                  className="navbar-brand mx-auto mt-2 flex-fill text-center"
                  href="./login"
                >
                  <img className="" src={logo} width="100%" alt="" />
                </a>
              </div>
              <Form.Group controlId="validationCustom01">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  required
                  type="email"
                  name="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  isValid={touched.email && !errors.email}
                  isInvalid={!!errors.email}
                />
                <Form.Control.Feedback>Correcto</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">
                  {errors.email}
                </Form.Control.Feedback>
              </Form.Group>
              <div className="form-row">
                <Form.Group as={Col} md="6">
                  <Form.Label>Nombre de la cuenta</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    name="userName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.userName}
                    isValid={touched.userName && !errors.userName}
                    isInvalid={!!errors.userName}
                  />
                  <Form.Control.Feedback>Correcto</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    {errors.userName}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <Form.Label>Número telefónico de contacto</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    name="cellNumber"
                    maxLength={8}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.cellNumber}
                    isValid={touched.cellNumber && !errors.cellNumber}
                    isInvalid={!!errors.cellNumber}
                  />
                  <Form.Control.Feedback>Correcto</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    {errors.cellNumber}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
              <hr className="my-4" />
              <div className="row mb-4">
                <Col md="6">
                  <Form.Group>
                    <Form.Label>Contraseña</Form.Label>
                    <Form.Control
                      required
                      type="password"
                      name="password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                      isValid={touched.password && !errors.password}
                      isInvalid={!!errors.password}
                    />
                    <Form.Control.Feedback>Correcto</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      {errors.password}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Confirmar la contraseña</Form.Label>
                    <Form.Control
                      required
                      type="password"
                      name="rePassword"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.rePassword}
                      isValid={touched.rePassword && !errors.rePassword}
                      isInvalid={!!errors.rePassword}
                    />
                    <Form.Control.Feedback>Correcto</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      {errors.rePassword}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <div className="col-md-6">
                  <p className="mb-2">Requerimientos de la contraseña</p>
                  <p className="small text-muted mb-2">
                    {" "}
                    La contraseña debe cumplir todos los siguientes
                    requerimientos
                  </p>
                  <ul className="small text-muted pl-4 mb-0">
                    <li> Mínimo 8 caracteres </li>
                    <li> Al menos una letra mayúscula</li>
                    <li> Al menos un número</li>
                    <li>Al menos un caracter especial</li>
                  </ul>
                </div>
              </div>
              <button
                className="btn btn-lg btn-primary btn-block"
                disabled={isSubmitting}
                type="submit"
              >
                Registrarse
              </button>
              <p className="mt-5 mb-3 text-muted text-center">
                Nuxway Technology © {new Date().getFullYear()}
              </p>
            </Form>
          </div>
        </div>
      )}
    </Formik>
  ) : (
    <RegisterSuccessfully email={email} cognitoUserName={cognitoUserName} />
  );
}
