import { useState, useEffect } from "react";
import { useLoaderData, useParams } from "react-router-dom";
import swal from "sweetalert";
import { Formik } from "formik";

import IVRList from "../../components/IVRList";
import { Card, Col, Form, Row } from "react-bootstrap";
import ContactListTable from "../../components/Table/ContactListTable";
import createAxiosInstance from "../../utils/axios.config";
import { useNavigate } from "react-router-dom";

function UpdateCampana() {
  const { id } = useParams(); // Obtener el ID del URL
  const { campania } = useLoaderData();
  const [id_campania, setId_campania] = useState();

  const client = createAxiosInstance();
  const [clientNumbers, setClientNumbers] = useState([]);
  const [ivrSelected, setIvrSelected] = useState();
  const [ivrSelectedName, setIvrSelectedName] = useState();
  const [campaignName, setCampaignName] = useState();

  const navigate = useNavigate();

  useEffect(() => {
    console.log("Campania:", campania);
    setCampaignName(campania.nombre_campania);
    setIvrSelected(campania.numero_ivr);
    setClientNumbers(campania.destinatarios);
    setIvrSelectedName(campania.nombre_ivr);
    setId_campania(campania.id_campania);
    console.log(
      "Revision de variables:",
      campaignName,
      ivrSelected,
      ivrSelectedName,
      id_campania
    );
  }, [campania]);

  return (
    <>
      <Formik
        initialValues={{ nombre_campana: "" }}
        validate={(values) => {
          const errors = {};
          if (!campaignName || campaignName.trim() === "") {
            errors.nombre_campana = "Campo requerido";
          } else if (campaignName.trim().length < 4) {
            errors.nombre_campana = "Nombre demasiado corto";
          }
          return errors;
        }}
        onSubmit={async (values, { setSubmitting }) => {
          if (clientNumbers.length > 0) {
            if (ivrSelected === undefined) {
              swal(
                "Error al crear la campaña",
                "Por favor seleccione un IVR",
                "warning"
              );
            } else {
              try {
                client({
                  url: "/campaign",
                  method: "PATCH",
                  data: {
                    id_campania: id,
                    nombre_campania: campaignName,
                    numero_ivr: ivrSelected,
                    nombre_ivr: ivrSelectedName,
                    destinatarios: clientNumbers,
                  },
                })
                  .then((res) => {
                    swal("Datos establecidos correctamente", "", "success");
                  })
                  .catch((e) => {
                    swal(
                      "Error",
                      "Ocurrió un error, por favor inténtelo mas tarde",
                      "error"
                    );
                  });
                navigate("/select-campaign");
              } catch (error) {
                swal(
                  "Error al crear el contacto",
                  "Ocurrió un error, por favor inténtelo más tarde",
                  "error"
                );
              }
            }
          } else {
            swal(
              "Error al crear la campaña",
              "Seleccione al menos un contacto para la campaña",
              "warning"
            );
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit} noValidate>
            <h2 className="page-title">Actualizar campaña</h2>
            <div className="text-right">
              <input
                type="submit"
                style={{ width: "250px" }}
                className="btn btn-primary text-white"
                value="Actualizar Campaña"
                disabled={isSubmitting}
              />
            </div>
            <br />
            <Row>
              <Col md={12}>
                <Card>
                  <Card.Body>
                    <Row>
                      <Col md={5}>
                        <Form.Group controlId="validationCustom01">
                          <Card.Title>
                            <label>Nombre de la campaña</label>
                          </Card.Title>
                          <Form.Control
                            type="text"
                            name="nombre_campana"
                            placeholder="Nombre de la campaña"
                            value={campaignName}
                            onChange={(e) => setCampaignName(e.target.value)}
                            onBlur={handleBlur}
                            isValid={
                              touched.nombre_campana && !errors.nombre_campana
                            }
                            isInvalid={!!errors.nombre_campana}
                          />
                          <Form.Control.Feedback style={{ fontSize: "0.9rem" }}>
                            Correcto
                          </Form.Control.Feedback>
                          <Form.Control.Feedback
                            type="invalid"
                            style={{ fontSize: "0.9rem" }}
                          >
                            {errors.nombre_campana}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col md={7}>
                        <Form.Group>
                          <Card.Title>Seleccionar IVR</Card.Title>
                          <IVRList
                            setIvrSelected={setIvrSelected}
                            setIvrSelectedName={setIvrSelectedName}
                            ivrNumber={campania.numero_ivr}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <br />
            <h4 className="page-title">Lista de destinatarios</h4>
            <br />
            <Row>
              <Col md={12}>
                <Card>
                  <Card.Body>
                    <Row>
                      <Col md={12}>
                        <br />
                        <ContactListTable
                          setClientNumbers={setClientNumbers}
                          selectedUserIds={campania.destinatarios}
                        />
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </>
  );
}
export default UpdateCampana;
