import React, { useEffect, useState } from "react";
import EditPbxFrom from "../components/Forms/EditPbxFrom";
import RegistryPbxFrom from "../components/Forms/RegistryPbxFrom";
import IvrGrid from "../components/Grids/IvrGrid";

export default function ConfigPBX() {
  return (
    <>
      <h3 className="page-title">Datos de la centralita IP</h3>
      <br />
      <RegistryPbxFrom />


      <div className="row">
        <h3 className="page-title">Datos para las llamadas</h3>
      </div>
      <div className="row">
        <div className="col-md-6">
          <EditPbxFrom />
        </div>
      </div>
    </>
  );
}
