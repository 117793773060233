import React from "react";
//import LineChart from "../LineChart/LineChart";
import VerticalBarChart from "../VerticalBarChart/VerticalBarChart";
import "./DashboardChart.css";
import { useEffect, useState } from "react";
import createAxiosInstance from "../../utils/axios.config";

export default function DashboardChart() {
  const client = createAxiosInstance();
  /*const [startGetAnnualCalls, { load, data }] = useGetAnnualCalls()*/
  const [meses, setMeses] = useState({
    enero: 0,
    febrero: 0,
    marzo: 0,
    mayo: 0,
    junio: 0,
    julio: 0,
    agosto: 0,
    septiembre: 0,
    octubre: 0,
    noviembre: 0,
    diciembre: 0,
  });
  const [aceptadas, setAceptadas] = useState();
  const [rechazadas, setRechazadas] = useState();

  useEffect(() => {
    client
      .get("reports/annualtotalcalls")
      .then((response) => {
        setMeses(response.data);
      })
      .catch((err) => {});
  }, []);
  useEffect(() => {
    client
      .get("reports/annualtotalaceptedrejected")
      .then((response) => {
        setAceptadas([
          response.data.enero[0],
          response.data.febrero[0],
          response.data.marzo[0],
          response.data.abril[0],
          response.data.mayo[0],
          response.data.junio[0],
          response.data.julio[0],
          response.data.agosto[0],
          response.data.septiembre[0],
          response.data.octubre[0],
          response.data.noviembre[0],
          response.data.diciembre[0],
        ]);
        setRechazadas([
          response.data.enero[1],
          response.data.febrero[1],
          response.data.marzo[1],
          response.data.abril[1],
          response.data.mayo[1],
          response.data.junio[1],
          response.data.julio[1],
          response.data.agosto[1],
          response.data.septiembre[1],
          response.data.octubre[1],
          response.data.noviembre[1],
          response.data.diciembre[1],
        ]);
      })
      .catch((err) => {});
  }, []);
  return (
    <div className="card show">
      <div className="card-header">
        <h5 className="card-title">Resumen Anual</h5>
      </div>
      <div className="card-body">
        <div className="row justify-content-center">
          {" "}
          {/* Cambiado center por justify-content-center */}
          <div className="col-md-6 d-flex justify-content-center">
            {" "}
            {/* Añadido d-flex y justify-content-center */}
            <VerticalBarChart meses={meses} />
          </div>
          {/* <div className="col-md-6">
            <LineChart aceptadas={aceptadas} rechazadas={rechazadas} />
          </div> */}
        </div>
        <br />
      </div>
    </div>
  );
}
