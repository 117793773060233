import axios from "axios";

const createAxiosInstance = () => {

  return axios.create({
    baseURL: "https://o3kf271lz8.execute-api.us-east-2.amazonaws.com/dev",
    headers: {
      Authorization: sessionStorage.getItem("token")
    },
  });
};

export default createAxiosInstance;
