import React from "react";
import { Table } from "antd";
import { useState, useEffect } from "react";

import createAxiosInstance from "../../utils/axios.config";
export default function CampaignDetail(props) {
  const client = createAxiosInstance();
  const { id_lanzar_campania } = props;
  const [dataSource, setDataSource] = useState();

  const columns = [
    {
      title: "Nombre del destinatario",
      dataIndex: "nombre",
      editable: true,
    },
    {
      title: "Apellido del destinatario",
      dataIndex: "apellido",
      editable: true,
    },
    {
      title: "Fecha de la llamada",
      dataIndex: "fecha_creacion",
    },
    {
      title: "Estado de la llamada",
      dataIndex: "estado_inicial",
    },
  ];
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    client
      .get(`reports/campaignlauncheddetail/${id_lanzar_campania}`)
      .then((response) => {
        const data = response.data.map((item) => {
          const dateObj = new Date(item.fecha_creacion);

          const year = dateObj.getUTCFullYear();
          const month = String(dateObj.getUTCMonth() + 1).padStart(2, "0");
          const day = String(dateObj.getUTCDate()).padStart(2, "0");
          const hours = String(dateObj.getUTCHours()).padStart(2, "0");
          const minutes = String(dateObj.getUTCMinutes()).padStart(2, "0");
          const seconds = String(dateObj.getUTCSeconds()).padStart(2, "0");

          const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

          item.fecha_creacion = formattedDate;

          return item
        });
        setDataSource(data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Table loading={loading} dataSource={dataSource} columns={columns} />
    </div>
  );
}
