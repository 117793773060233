import React, { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import { Formik } from "formik";
import { Button, Col, Form } from "react-bootstrap";
import swal from "sweetalert";
export default function ConfigProfile() {
  const [numero_telefono, setNumero_telefono] = useState("");
  const [nombre_usuario, setNombre_usuario] = useState("");
  const [user, setUser] = useState();
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const currentUser = await Auth.currentAuthenticatedUser();
        setUser(currentUser);
        console.log(currentUser);
        setNumero_telefono(currentUser.attributes["custom:cell_phone"]);
        setNombre_usuario(currentUser.attributes["custom:user_name"]);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, []);

  return (
    <div className="col-12 col-lg-10 col-xl-8">
      <h2 className="h3 mb-4 page-title">Configuraciones</h2>
      <div className="my-4">
        <ul className="nav nav-tabs mb-4" id="myTab" role="tablist">
          <li className="nav-item">
            <a
              className="nav-link active"
              id="home-tab"
              data-toggle="tab"
              role="tab"
              aria-controls="home"
              aria-selected="true"
            >
              Cuenta de usuario
            </a>
          </li>
        </ul>
        <div className="">
          <div className=" align-items-center"></div>
          <Formik
            initialValues={{
              userName: numero_telefono,
              cellNumber: nombre_usuario,
            }}
            validate={(values) => {
              const errors = {};
              //validacion de nombre de usuario
              if (!nombre_usuario) {
                errors.userName = "Campo requerido";
              } else if (!/^[a-zA-Z\d\s]*$/g.test(values.userName)) {
                errors.userName = "Nombre de usuario inválido";
              }
              //validacion de numero de contacto
              if (!numero_telefono) {
                errors.cellNumber = "Campo requerido";
              } else if (!/(4|6|7)([0-9]){7}/.test(numero_telefono)) {
                errors.cellNumber = "Numero de celular inválido";
              }
              //validacion contraseña
              return errors;
            }}
            onSubmit={async (values, { setSubmitting }) => {
              try {
                const updatedAttributes = {
                  "custom:user_name": nombre_usuario,
                  "custom:cell_phone": numero_telefono,
                };

                await Auth.updateUserAttributes(user, updatedAttributes);
                swal(
                  "Guardado con éxito",
                  `datos actualizados correctamente`,
                  "success"
                );
              } catch (error) {
                swal(
                  "Error",
                  `Error al actualizar los datos: ${error.message}`,
                  "error"
                );
              }
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              /* and other goodies */
            }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <hr className="my-4" />
                <div className="form-row mb-4">
                  <Form.Group as={Col} md="6">
                    <Form.Label>Nombre de la cuenta</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      name="userName"
                      onChange={(e) => setNombre_usuario(e.target.value)}
                      onBlur={handleBlur}
                      value={nombre_usuario}
                      isValid={touched.userName && !errors.userName}
                      isInvalid={!!errors.userName}
                    />
                    <Form.Control.Feedback>Correcto</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      {errors.userName}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md="6">
                    <Form.Label>Número telefónico de contacto</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      name="cellNumber"
                      maxLength={8}
                      onChange={(e) => setNumero_telefono(e.target.value)}
                      onBlur={handleBlur}
                      value={numero_telefono}
                      isValid={touched.cellNumber && !errors.cellNumber}
                      isInvalid={!!errors.cellNumber}
                    />
                    <Form.Control.Feedback>Correcto</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      {errors.cellNumber}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
                <Button type="submit">Guardar</Button>
              </Form>
            )}
          </Formik>

          <Formik
            initialValues={{
              oldPassword: "",
              newPassword: "",
              repeatNewPassword: "",
            }}
            validate={(values) => {
              const errors = {};
              //validacion de la antigua contraseña
              if (!values.oldPassword) {
                errors.oldPassword = "Campo requerido";
              } else if (
                !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(
                  values.oldPassword
                )
              ) {
                errors.oldPassword = "La contraseña no cumple los requisitos";
              }
              //validacion de la nueva contraseña
              if (!values.newPassword) {
                errors.newPassword = "Campo requerido";
              } else if (
                !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(
                  values.newPassword
                )
              ) {
                errors.newPassword = "La contraseña no cumple los requisitos";
              }
              // validacion de la repeticion de la contraseña
              if (!values.repeatNewPassword) {
                errors.repeatNewPassword = "Campo requerido";
              } else if (values.repeatNewPassword !== values.newPassword) {
                errors.repeatNewPassword = "La contraseña no coincide";
              }
              return errors;
            }}
            onSubmit={async (values, { setSubmitting }) => {
              try {
                await Auth.updatePassword(
                  user,
                  values.oldPassword,
                  values.newPassword
                );

                swal(
                  "Contraseña cambiada",
                  `la contraseña se cambió correctamente`,
                  "success"
                );
              } catch (error) {
                swal(
                  "Error",
                  `Error al actualizar los datos: ${error.message}`,
                  "error"
                );
              }
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              /* and other goodies */
            }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <hr className="my-4" />
                <div className="row mb-4">
                  <div className="col-md-6">
                    <Form.Group>
                      <Form.Label>Contraseña antigua</Form.Label>
                      <Form.Control
                        required
                        type="password"
                        name="oldPassword"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.oldPassword}
                        isValid={touched.oldPassword && !errors.oldPassword}
                        isInvalid={!!errors.oldPassword}
                      />
                      <Form.Control.Feedback>Correcto</Form.Control.Feedback>
                      <Form.Control.Feedback type="invalid">
                        {errors.oldPassword}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group>
                      <Form.Label>Nueva contraseña</Form.Label>
                      <Form.Control
                        required
                        type="password"
                        name="newPassword"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.newPassword}
                        isValid={touched.newPassword && !errors.newPassword}
                        isInvalid={!!errors.newPassword}
                      />
                      <Form.Control.Feedback>Correcto</Form.Control.Feedback>
                      <Form.Control.Feedback type="invalid">
                        {errors.newPassword}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Confirmar contraseña</Form.Label>
                      <Form.Control
                        required
                        type="password"
                        name="repeatNewPassword"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.repeatNewPassword}
                        isValid={
                          touched.repeatNewPassword && !errors.repeatNewPassword
                        }
                        isInvalid={!!errors.repeatNewPassword}
                      />
                      <Form.Control.Feedback>Correcto</Form.Control.Feedback>
                      <Form.Control.Feedback type="invalid">
                        {errors.repeatNewPassword}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <p className="mb-2">Requerimientos de la contraseña</p>
                    <p className="small text-muted mb-2">
                      la contraseña debe cumplir todos los siguientes
                      requerimientos:{" "}
                    </p>
                    <ul className="small text-muted pl-4 mb-0">
                      <li>Mínimo 8 caracteres</li>
                      <li>Al menos una letra mayúscula</li>
                      <li>Al menos un número</li>
                      <li>Al menos un caracter especial</li>
                    </ul>
                  </div>
                </div>
                <button type="submit" className="btn btn-primary">
                  Cambiar contraseña
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}
