import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import NavSidebar from "../NavSidebar/NavSidebar";
import { Outlet } from "react-router-dom";
import { Auth } from "aws-amplify";

function MainPage() {
  const navigate = useNavigate();
  useEffect(() => {
    if (!sessionStorage.getItem("token")) navigate("/login");
    // setInterval(() => {
    //   refreshSession();
    // }, 1800000);
  }, []);

  async function refreshSession() {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const currentSession = await Auth.currentSession();
      const refreshToken = currentSession.getRefreshToken();

      const newSession = await new Promise((resolve, reject) => {
        user.refreshSession(refreshToken, (err, session) => {
          if (err) {
            reject(err);
          } else {
            resolve(session);
          }
        });
      });

      console.log("New session:", newSession);
      sessionStorage.setItem("token", newSession.idToken.jwtToken);
    } catch (error) {
      console.error("Error refreshing session:", error);
    }
  }

  return sessionStorage.getItem("token") ? (
    <>
      <NavSidebar />
      <main role="main" className="main-content">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-12">
              <Outlet />
            </div>
          </div>
        </div>
      </main>
    </>
  ) : (
    navigate("/login")
  );
}
export default MainPage;
