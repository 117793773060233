import { createBrowserRouter } from "react-router-dom";
import MainPage from "../components/MainPage/MainPage";
import Login from "../Pages/Login/Login";
import Dashboard from "../Pages/Dashboard";
import ErrorPage from "../Pages/ErrorPage/ErrorPage";
import ListarContactos from "../Pages/Contactos/ListarContactos";
import ConfigPBX from "../Pages/ConfigPBX";
import CrearCampania from "../Pages/Campanias/CrearCampania";
import SeleccionarCampana from "../Pages/Campanias/SeleccionarCampana";
import LanzarCampana from "../Pages/Campanias/LanzarCampana";
import ConfigProfile from "../Pages/ConfigProfile";
import CrearContacto from "../Pages/Contactos/CrearContacto";
import RegistrarUsuario from "../Pages/RegistrarUsuario";
import { loader as lanzarCamLoader } from "./campania";
import { loader as campaniaDetalleLoader } from "./reportes";
import CampaignLaunchedDetail from "../Pages/CampaignLaunchedDetail";
import UpdateContacto from "../Pages/Contactos/UpdateContacto";
import UpdateCampana from "../Pages/Campanias/EditCampaign";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Login />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/",
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/registry",
        element: <RegistrarUsuario />,
      },
      {
        element: <MainPage />,
        children: [
          {
            path: "/dashboard",
            element: <Dashboard />,
          },
          {
            path: "/dashboard/:id",
            element: <CampaignLaunchedDetail />,
            loader:campaniaDetalleLoader
          },
          {
            path: "pbx-management",
            element: <ConfigPBX />,
          },
          {
            path: "/contact-list",
            element: <ListarContactos />,
          },

          {
            path: "create-campaign",
            element: <CrearCampania />,
          },
          
          {
            path: "editcontact",
            element: <UpdateContacto />,
          },
          {
            path: "select-campaign",
            element: <SeleccionarCampana />,
          },
          {
            path: "/edit-profile",
            element: <ConfigProfile />,
          },
          {
            path: "new-contact",
            element: <CrearContacto />,
          },
          {
            path: "edit-campaign/:id",
            element: <UpdateCampana />,
            loader:lanzarCamLoader
          },
          {
            path: "launch-campaign/:id",
            element: <LanzarCampana />,
            loader:lanzarCamLoader
          },
        ],
      },
    ],
  },
]);
