import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { Form, FormControl, Row } from "react-bootstrap";
import createAxiosInstance from "../../utils/axios.config";
import { useNavigate, useSearchParams } from "react-router-dom";
import swal from "sweetalert";

export default function UpdateContacto() {
  const client = createAxiosInstance();
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");

  const [dataSource, setDataSource] = useState(null); // Inicializamos con null para verificar la carga

  useEffect(() => {
    client
      .get("contact")
      .then((response) => {
        const contact = response.data.find(
          (item) => item.id_destinatario.toString() === id
        );

        if (contact) {
          const dateObj = new Date(contact.fechaNacimiento);
          const year = dateObj.getUTCFullYear();
          const month = String(dateObj.getUTCMonth() + 1).padStart(2, "0");
          const day = String(dateObj.getUTCDate()).padStart(2, "0");
          const formattedDate = `${year}-${month}-${day}`;

          contact.fechaNacimiento =
            formattedDate === "NaN-NaN-NaN" ? "" : formattedDate;

          setDataSource(contact);
        } else {
          console.error("Contacto no encontrado");
        }
      })
      .catch((err) => {
        console.error("Error al obtener los contactos", err);
      });
  }, [id]);

  const navigate = useNavigate();

  if (!dataSource) {
    return <div>Loading...</div>; // Muestra un indicador de carga mientras se cargan los datos
  }

  return (
    <div className="col-12 col-xl-10">
      <Formik
        initialValues={{
          id_destinatario: id,
          email: dataSource.email,
          firstname: dataSource.nombre,
          lastname: dataSource.apellido,
          cellNumber: dataSource.numero_celular,
          date: dataSource.fechaNacimiento,
          gender: dataSource.genero,
        }}
        validate={(values) => {
          const errors = {};
          if (!values.firstname) {
            errors.firstname = "Campo requerido";
          } else if (!/^[A-Za-z\s]+$/g.test(values.firstname)) {
            errors.firstname = "Formato de nombre incorrecto";
          }
          if (!values.lastname) {
            errors.lastname = "Campo requerido";
          } else if (!/^[A-Za-z\s]+$/g.test(values.lastname)) {
            errors.lastname = "Formato de apellido incorrecto";
          }
          if (!values.cellNumber) {
            errors.cellNumber = "Campo requerido";
          } else if (!/(6|7)([0-9]){7}/.test(values.cellNumber)) {
            errors.cellNumber = "Número de celular inválido";
          }
          return errors;
        }}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            client({
              url: "/contact",
              method: "PATCH",
              data: {
                id_destinatario: values.id_destinatario,
                nombre: values.firstname,
                apellido: values.lastname,
                email: values.email,
                numero_celular: values.cellNumber,
                fechaNacimiento: values.date,
                genero: values.gender,
              },
            })
              .then((res) => {
                swal("Datos establecidos correctamente", "", "success");
              })
              .catch((e) => {
                swal(
                  "Error",
                  "Ocurrió un error, por favor inténtelo mas tarde",
                  "error"
                );
              });
            navigate("/contact-list");
          } catch (error) {
            swal(
              "Error al crear el contacto",
              "Ocurrió un error, por favor inténtelo más tarde",
              "error"
            );
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <>
            <div className="row">
              <div className="col">
                <h2 className="h3 mb-0 page-title">Actualizar contacto</h2>
              </div>
            </div>
            <Form noValidate onSubmit={handleSubmit}>
              <hr className="my-4" />
              <Row>
                <Form.Group className="col-md-6">
                  <Form.Label htmlFor="firstname">Nombre Completo</Form.Label>
                  <Form.Control
                    type="text"
                    id="firstname"
                    required
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.firstname}
                    isValid={touched.firstname && !errors.firstname}
                    isInvalid={!!errors.firstname}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.firstname}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="col-md-6">
                  <Form.Label htmlFor="lastname">Apellidos</Form.Label>
                  <Form.Control
                    type="text"
                    id="lastname"
                    required
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.lastname}
                    isValid={touched.lastname && !errors.lastname}
                    isInvalid={!!errors.lastname}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.lastname}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group className="col-md-8">
                  <Form.Label htmlFor="email">Correo electrónico</Form.Label>
                  <Form.Control
                    type="email"
                    id="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    isValid={touched.email && !errors.email}
                    isInvalid={!!errors.email}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.email}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="col-md-4">
                  <Form.Label htmlFor="cellNumber">
                    Número de teléfono celular
                  </Form.Label>
                  <Form.Control
                    type="text"
                    required
                    id="cellNumber"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.cellNumber}
                    isValid={touched.cellNumber && !errors.cellNumber}
                    isInvalid={!!errors.cellNumber}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.cellNumber}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group className="col-md-4">
                  <Form.Label htmlFor="date">Fecha de nacimiento</Form.Label>
                  <FormControl
                    id="date"
                    type="date"
                    name="date"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.date}
                    isValid={touched.date && !errors.date}
                    isInvalid={!!errors.date}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.date}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="col-md-4">
                  <Form.Label htmlFor="gender">Género</Form.Label>
                  <Form.Select
                    id="gender"
                    className="form-control"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.gender}
                    isValid={touched.gender && !errors.gender}
                    isInvalid={!!errors.gender}
                  >
                    <option value=""></option>
                    <option value="Masculino">Masculino</option>
                    <option value="Femenino">Femenino</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {errors.gender}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <hr className="my-4" />
              <button
                type="submit"
                disabled={isSubmitting}
                className="btn btn-primary"
              >
                <span className="fe fe-save fe-12 mr-2"></span>Guardar
              </button>
            </Form>
          </>
        )}
      </Formik>
    </div>
  );
}
