import React, { useEffect, useState } from "react";
import createAxiosInstance from "../../utils/axios.config";
import { Button, Form, Row, Col } from "react-bootstrap";
import { Formik } from "formik";
import swal from "sweetalert";

export default function EditPbxForm() {
  const client = createAxiosInstance();
  const [formData, setFormData] = useState({
    extension: "",
    ruta: "",
    trunkName: ""
  });

  useEffect(() => {
    client
      .get("privatebranchexchange")
      .then((response) => {
        const { extension, ruta, trunk } = response.data;
        setFormData({ extension, ruta, trunk });
      })
      .catch((err) => {
        console.error("Error al obtener datos:", err);
      });
  }, []);

  return (
    <Formik
      initialValues={formData}
      enableReinitialize
      validate={(values) => {
        const errors = {};
        if (!values.extension) {
          errors.extension = "Campo requerido";
        } else if (!/^[0-9]+$/.test(values.extension)) {
          errors.extension = "El valor de la extensión debe ser numérico";
        }
        if (!values.ruta) {
          errors.ruta = "Campo requerido";
        } else if (!/^[0-9]+$/.test(values.ruta)) {
          errors.ruta = "El valor de la ruta debe ser numérico";
        }
        if (!values.trunk) {
          errors.trunk = "Campo requerido";
        }
        return errors;
      }}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          await client.patch("/privatebranchexchange", values);
          swal("Datos establecidos correctamente", "", "success");
        } catch (e) {
          swal("Error", "Ocurrió un error, por favor inténtelo más tarde", "error");
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Row>
            <Col md={4}>
              <Form.Group>
                <Form.Label>Numero de la extensión</Form.Label>
                <Form.Control
                  required
                  type="text"
                  name="extension"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.extension}
                  isValid={touched.extension && !errors.extension}
                  isInvalid={!!errors.extension}
                />
                <Form.Control.Feedback>Correcto</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">
                  {errors.extension}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group>
                <Form.Label>Numero de la ruta</Form.Label>
                <Form.Control
                  required
                  type="text"
                  name="ruta"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.ruta}
                  isValid={touched.ruta && !errors.ruta}
                  isInvalid={!!errors.ruta}
                />
                <Form.Control.Feedback>Correcto</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">
                  {errors.ruta}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group>
                <Form.Label>Nombre de Troncal</Form.Label>
                <Form.Control
                  required
                  type="text"
                  name="trunk"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.trunk}
                  isValid={touched.trunk && !errors.trunk}
                  isInvalid={!!errors.trunk}
                />
                <Form.Control.Feedback>Correcto</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">
                  {errors.trunk}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Form.Group>
            <br />
            <Button type="submit" disabled={isSubmitting}>Guardar Datos</Button>
          </Form.Group>
        </Form>
      )}
    </Formik>
  );
}
