import { useState, useEffect } from "react";
import { useLoaderData } from "react-router-dom";
import swal from "sweetalert";
import Grid from "../../components/Grid/Grid";
import IVRCard from "../../components/IVRCard/IVRCard";
import CallProcessor from "../../components/CallProcessor2";

var index = 0;
function LanzarCampana() {
  const { campania } = useLoaderData();
  const [nombreCampania, setNombreCampania] = useState();
  const [numeroIvr, setNumeroIvr] = useState();
  const [nombreIvr, setNombreIvr] = useState();
  const [destinatarios, setDestinatarios] = useState();
  const [id_campania, setId_campania] = useState()


  useEffect(() => {
    setNombreCampania(campania.nombre_campania);
    setNumeroIvr(campania.numero_ivr);
    setDestinatarios(campania.destinatarios)
    setNombreIvr(campania.nombre_ivr);
    setId_campania(campania.id_campania)
  }, []);


  return (
    <>
      <h2 className="h3 mb-0 page-title">{nombreCampania}</h2>
      <br />
      <div className="row">
        <div className="col-md-12">
          <div className="card shadow">
            <div className="card-body">
              <div className="form-group mb-3 row">
                <div className="col-md-6">
                  {<IVRCard value={numeroIvr} ivrname={nombreIvr} />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <CallProcessor
        destinatarios={destinatarios}
        setDestinatarios={setDestinatarios}
        id_campania={id_campania}
        numeroIvr={numeroIvr} />
      <hr />
      {<Grid destinatarios={destinatarios} setDestinatarios={setDestinatarios} />}
    </>
  );
}
export default LanzarCampana;
