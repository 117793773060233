import { useEffect, useState } from "react";
import ivrIcon from "../../images/chatbot.png";
import "./IVRCard.css";

function IVRCard({ value, ivrname}) {
    return (
        <>
            <label className="text-center">
                <input type="radio"
                    defaultChecked
                    name="ivr"
                    value={value}/>
                <img src={ivrIcon} alt="IVR" height={100} />
                <p>{`${value} ${ivrname}`}</p>
            </label>
        </>
    )
}

export default IVRCard