import React, { useState } from "react";
import { Formik } from "formik";
import { Form, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import createAxiosInstance from "../../utils/axios.config";

export default function CrearContacto() {
  const client = createAxiosInstance();
  const navigate = useNavigate();

  return (
    <div className="col-12 col-xl-10">
      <Formik
        initialValues={{
          email: "",
          firstname: "",
          lastname: "",
          cellNumber: "",
          date: "",
          gender: "",
        }}
        validate={(values) => {
          const errors = {};
          // Validación del nombre de contacto
          if (!values.firstname) {
            errors.firstname = "Campo requerido";
          } else if (!/^[A-Za-z\s]+$/g.test(values.firstname)) {
            errors.firstname = "Formato de nombre incorrecto";
          }
          // Validación del apellido de contacto
          if (!values.lastname) {
            errors.lastname = "Campo requerido";
          } else if (!/^[A-Za-z\s]+$/g.test(values.lastname)) {
            errors.lastname = "Formato de apellido incorrecto";
          }
          // Validación del apellido de contacto
          if (!values.cellNumber) {
            errors.cellNumber = "Campo requerido";
          }
        }}
        onSubmit={async (values, { setSubmitting }) => {
          client
            .post("contact", {
              nombre: values.firstname,
              apellido: values.lastname,
              email: values.email,
              numero_celular: values.phoneNumber,
              fechaNacimiento: values.date,
              genero: values.gender,
            })
            .then((response) => {
              navigate("/contact-list");
            })
            .catch((error) => {
              swal(
                "Error al crear el contacto",
                "Ocurrió un error, por favor inténtelo más tarde",
                "error"
              );
            });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
        }) => (
          <>
            <div className="row">
              <div className="col">
                <h2 className="h3 mb-0 page-title">Agregar contacto</h2>
              </div>
            </div>
            <Form noValidate onSubmit={handleSubmit}>
              <hr className="my-4" />
              <Row>
                <Form.Group as={Col} md="6">
                  <Form.Label>Nombre de la cuenta</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    name="firstname"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.firstname}
                    isValid={touched.firstname && !errors.firstname}
                    isInvalid={!!errors.firstname}
                  />
                  <Form.Control.Feedback>Correcto</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    {errors.firstname}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <Form.Label>Apellidos</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    name="lastname"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.lastname}
                    isValid={touched.lastname && !errors.lastname}
                    isInvalid={!!errors.lastname}
                  />
                  <Form.Control.Feedback>Correcto</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    {errors.lastname}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col} md="6">
                  <Form.Label>Correo electrónico</Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    isValid={touched.email && !errors.email}
                    isInvalid={!!errors.email}
                  />
                  <Form.Control.Feedback>Correcto</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    {errors.email}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <Form.Label>Número telefónico de contacto</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    name="cellNumber"
                    maxLength={8}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.cellNumber}
                    isValid={touched.cellNumber && !errors.cellNumber}
                    isInvalid={!!errors.cellNumber}
                  />
                  <Form.Control.Feedback>Correcto</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    {errors.cellNumber}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col} md="4">
                  <Form.Label>Fecha de nacimiento</Form.Label>
                  <Form.Control
                    type="date"
                    name="date"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.date}
                    isValid={touched.date && !errors.date}
                    isInvalid={!!errors.date}
                  />
                  <Form.Control.Feedback>Correcto</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    {errors.date}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="col-md-4">
                  <Form.Label htmlFor="gender">Género</Form.Label>
                  <Form.Select
                    id="gender"
                    className="form-control"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.gender}
                    isValid={touched.gender && !errors.gender}
                    isInvalid={!!errors.gender}
                  >
                    <option defaultValue></option>
                    <option value="Masculino">Masculino</option>
                    <option value="Femenino">Femenino</option>
                  </Form.Select>
                  <Form.Control.Feedback>Correcto</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    {errors.gender}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <hr className="my-4" />
              <button
                type="submit"
                disabled={isSubmitting}
                className="btn btn-primary"
              >
                <span className="fe fe-save fe-12 mr-2"></span>Guardar
              </button>
            </Form>
          </>
        )}
      </Formik>
    </div>
  );
}
