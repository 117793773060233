import React, { useRef, useState, useEffect, useCallback } from "react";
import createAxiosInstance from "../utils/axios.config";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";

export default function CallProcessor({
  destinatarios,
  setDestinatarios,
  id_campania,
  numeroIvr,
}) {
  const client = createAxiosInstance();
  const navigate = useNavigate();
  const model = localStorage.getItem("pbxmodel");

  const intervalValue = 6;
  const [numLines, setNumLines] = useState(1);
  const [isPaused, setIsPaused] = useState(true);
  const [idCampaignLaunched, setIdCampaignLaunched] = useState(0);

  const currentIndex = useRef(0);
  const isCancelled = useRef(false);
  const callid = useRef(null);
  const callInCurse = useRef(false);

  useEffect(() => {
    console.log("Este es el Call Processor 2!");
  }, []);

  const handleNumLinesChange = (event) => {
    setNumLines(event.target.value);
  };

  const checkCall = useCallback(async () => {
    console.log("Revisando si hay llamada...");

    if (callid.current === null) {
      console.log("Sin llamadas!", callid);
      return;
    }

    console.log("id actual:", callid.current, model);

    try {
      const response = await client.post("checkcall", { model });

      console.log("Respuesta de callid:", response);

      const { status, calldata, trunk } = response.data;

      if (status !== "Success") {
        throw new Error(response.data.errmsg || "La respuesta no fue exitosa");
      }

      if (!Array.isArray(calldata) || calldata.length === 0) {
        callid.current = null;
        callInCurse.current = false;
        setTimeout(() => {}, 2000); // 2 segundos de espera
        return;
      }

      let count = 0;

      for (let call of calldata) {
        if (!call || !call.members || !Array.isArray(call.members)) continue;
        for (let member of call.members) {
          if (member.outbound && member.outbound.trunk_name === trunk) {
            count++;
            console.log("LLamada encontrada en trunk!:", count);
          } else if (member.inbound && member.inbound.trunk_name === trunk) {
            count++;
            console.log("LLamada encontrada en trunk!:", count);
          }
        }
      }

      if (count < numLines) {
        console.log("Puede llamar");
        callInCurse.current = false;
      } else {
        console.log("No puede llamar!");
        callInCurse.current = true;
      }
    } catch (err) {
      console.error("Error en checkCall:", err);
      swal(
        "Ocurrió un error",
        err.message || "Por favor inténtelo más tarde",
        "error"
      );
      setIsPaused(true);
    }
  }, [client, model, numLines]); // Dependencias que podrían cambiar

  const makeCall = useCallback(
    async (id_dest, phoneNumber) => {
      if (callInCurse.current) {
        console.log("Hay una llamada en curso!");
        return;
      }

      console.log("Llamando a un número...");
      try {
        const response = await client.post("call", {
          numero_ivr: numeroIvr,
          destinatario: phoneNumber,
          id_lanzar_campania: idCampaignLaunched,
          id_destinatario: id_dest,
          model,
        });

        const destAux = destinatarios.map((dest) => {
          if (id_dest === dest.id_destinatario) {
            dest.estado =
              response.data.status === "Success"
                ? "Llamada Realizada"
                : `Error: ${response.data.errno}`;
          }
          return dest;
        });

        setDestinatarios([...destAux]);

        if (response.data.status === "Success") {
          callid.current = response.data.callid;
        }
      } catch (err) {
        console.error("Error en makeCall:", err);
        swal("Ocurrió un error", "Por favor inténtelo más tarde", "error");
        setIsPaused(true);
      }
    },
    [
      client,
      destinatarios,
      idCampaignLaunched,
      model,
      numeroIvr,
      setDestinatarios,
    ]
  );

  const processCalls = useCallback(async () => {
    if (currentIndex.current >= destinatarios.length) {
      setIsPaused(true);
      swal("La campaña se ha completado", { icon: "success" });
      return;
    }

    if (!isPaused && !isCancelled.current) {
      await checkCall();
      if (!callInCurse.current) {
        const { id_destinatario, numero_celular } =
          destinatarios[currentIndex.current];
        await makeCall(id_destinatario, numero_celular);
        currentIndex.current++;
      }
    }
  }, [checkCall, destinatarios, isPaused, makeCall]);

  useEffect(() => {
    if (!isPaused && !isCancelled.current) {
      const interval = setInterval(processCalls, intervalValue * 1000);
      return () => clearInterval(interval);
    }
  }, [isPaused, processCalls, intervalValue]);

  const handleCampaignAction = async (action) => {
    try {
      if (action === "start") {
        const response = await client.post("campaignlaunched", { id_campania });
        setIdCampaignLaunched(response.data.id_lanzar_campania);
        setIsPaused(false);
        isCancelled.current = false;
      } else if (action === "cancel") {
        swal({
          title: "Finalizar campaña",
          text: "¿Esta seguro de finalizar la campaña?",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            isCancelled.current = true;
            setIsPaused(true);
            navigate("/select-campaign");
          }
        });
      } else {
        setIsPaused(action === "pause");
      }
    } catch (err) {
      console.error(`Error en ${action}Campaign:`, err);
      swal("Ocurrió un error", "Por favor inténtelo más tarde", "error");
    }
  };

  return (
    <div className="text-right d-flex flex-wrap justify-content-end align-items-center">
      <form className="mb-2 mr-sm-1 d-flex align-items-center">
        <label className="mr-2 mb-0" style={{ whiteSpace: "nowrap" }}>
          Cantidad de Líneas:
        </label>
        <input
          type="number"
          className="form-control form-control-sm mr-2 bg-white border border-dark"
          style={{ width: "70px" }}
          value={numLines}
          onChange={handleNumLinesChange}
          min="1"
        />
      </form>
      <button
        type="button"
        className="btn mb-2 btn-success text-white mr-1"
        disabled={idCampaignLaunched !== 0}
        onClick={() => handleCampaignAction("start")}
      >
        <span className="fe fe-phone-forwarded fe-16 mr-1"></span>
        Iniciar Llamadas
      </button>
      <button
        className="btn mb-2 btn-warning text-white mr-1"
        disabled={isPaused}
        onClick={() => handleCampaignAction("pause")}
      >
        <span className="fe fe-pause fe-16 mr-1"></span>
        Pausar
      </button>
      <button
        className="btn mb-2 btn-info text-white mr-1"
        disabled={!isPaused}
        onClick={() => handleCampaignAction("resume")}
      >
        <span className="fe fe-play fe-16 mr-1"></span>
        Reanudar
      </button>
      <button
        className="btn mb-2 btn-danger text-white mr-1"
        onClick={() => handleCampaignAction("cancel")}
      >
        <span className="fe fe-stop-circle fe-16 mr-1"></span>
        Cancelar
      </button>
    </div>
  );
}
