/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button, Input, Space, Popconfirm, Table } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import React, { useEffect, useRef, useState } from "react";
import createAxiosInstance from "../../utils/axios.config";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";

export default function ContactListTable(props) {
  const client = createAxiosInstance();
  const { setClientNumbers, selectedUserIds = [], contacts } = props;
  const navigate = useNavigate();

  const [dataSource, setDataSource] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "Nombre",
      dataIndex: "nombre",
      editable: true,
      ...getColumnSearchProps("nombre"),
    },
    {
      title: "Apellido",
      dataIndex: "apellido",
      ...getColumnSearchProps("apellido"),
    },
    {
      title: "Email",
      dataIndex: "email",
      ...getColumnSearchProps("email"),
    },
    {
      title: "Número telefónico",
      dataIndex: "numero_celular",
      ...getColumnSearchProps("numero_celular"),
    },
    {
      title: "Fecha Nacimiento",
      dataIndex: "fechaNacimiento",
      ...getColumnSearchProps("fechaNacimiento"),
    },
    {
      title: "Género",
      dataIndex: "genero",
      ...getColumnSearchProps("genero"),
    },
    {
      title: "Acción",
      dataIndex: "operation",
      render: (_, record) =>
        dataSource.length >= 1 ? (
          <>
            <Popconfirm
              title="¿Desea editar el contacto de la lista?"
              onConfirm={() => handleUpdate(record.key)}
            >
              <span className="fe fe-edit fe-12 mr-2"></span>
              <a>Editar</a>
            </Popconfirm>
            <br />
            <Popconfirm
              title="¿Desea quitar el contacto?"
              onConfirm={() => handleDelete(record.key)}
            >
              <span className="fe fe-trash fe-12 mr-2"></span>
              <a>Quitar</a>
            </Popconfirm>
          </>
        ) : null,
    },
  ];
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    if (contacts) {
      setDataSource(contacts);
      setLoading(false);
    } else {
      client
        .get("contact")
        .then((response) => {
          const formattedData = response.data.map((item) => {
            item.key = item.id_destinatario;
            const dateObj = new Date(item.fechaNacimiento);

            const year = dateObj.getUTCFullYear();
            const month = String(dateObj.getUTCMonth() + 1).padStart(2, "0");
            const day = String(dateObj.getUTCDate()).padStart(2, "0");

            let formattedDate = `${year}-${month}-${day}`;
            if (formattedDate === "NaN-NaN-NaN") formattedDate = "";

            item.fechaNacimiento = formattedDate;
            return item;
          });

          setDataSource(formattedData);

          // Set the selected row keys based on the selectedUserIds prop
          const selectedKeys = selectedUserIds.map(
            (user) => user.id_destinatario
          );
          setSelectedRowKeys(selectedKeys);

          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contacts]);

  const handleDelete = (key) => {
    const newData = dataSource.filter((item) => item.key !== key);
    setDataSource(newData);
    setSelectedRowKeys(
      selectedRowKeys.filter((selectedKey) => selectedKey !== key)
    );
    try {
      client({
        url: "/contact",
        method: "DELETE",
        data: {
          id_destinatario: key,
        },
      })
        .then((res) => {
          swal("Registro borrado correctamente", "", "success");
        })
        .catch((e) => {
          swal(
            "Error",
            "Ocurrió un error, por favor inténtelo mas tarde",
            "error"
          );
        });
    } catch (error) {
      swal(
        "Error al crear el contacto",
        "Ocurrió un error, por favor inténtelo más tarde",
        "error"
      );
    }
  };

  const handleUpdate = (key) => {
    const dataToUpdate = dataSource.find((item) => item.key === key);
    if (dataToUpdate) {
      navigate(`/editcontact?id=${dataToUpdate.key}`);
    } else {
      console.error("No data found with the provided key");
    }
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys, selectedRows) => {
      setSelectedRowKeys(newSelectedRowKeys);
      if (setClientNumbers) {
        setClientNumbers(newSelectedRowKeys);
      }
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      name: record.name,
    }),
  };

  return (
    <div>
      <Table
        loading={loading}
        rowSelection={{
          type: "checkbox",
          ...rowSelection,
        }}
        dataSource={dataSource}
        columns={columns}
      />
    </div>
  );
}
