import Grid from "../../components/Grid/Grid";
import { useEffect, useState } from "react";
import CampaignsTable from "../../components/Table/CampaignsTable";
import { useNavigate } from "react-router-dom";

function SeleccionarCampana({ setPageCallBack }) {
  const [gridData, setGridData] = useState();
  const navigate = useNavigate();

  return (
    <div className="col-12">
      <div className="row align-items-center my-4">
        <div className="col">
          <h2 className="h3 mb-0 page-title">Lista de campañas</h2>
        </div>
        
      </div>

      <div className="card shadow">
        <div className="card-body">
          <CampaignsTable />
        </div>
      </div>
    </div>
  );
}

export default SeleccionarCampana;
