import { useNavigate } from "react-router-dom"

function NavItem(props) {
    
    const navigate = useNavigate()

    const { name, icon, route } = props
    return (
        <li className="nav-item dropdown">
            <a aria-expanded="false" className="nav-link" onClick={() => {
                navigate(route)

            }}>
                <i className={icon}></i>
                <span className="ml-3 item-text">{name}</span>
            </a>
        </li>
    )
}

export default NavItem