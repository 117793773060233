import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Modal } from "antd";
import ContactListTable from "../../components/Table/ContactListTable";
import DragAndDrop from "../../components/DragNDropAntd";
import createAxiosInstance from "../../utils/axios.config";

export default function ListarContactos() {
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [contacts, setContacts] = useState([]);
  const client = createAxiosInstance();

  useEffect(() => {
    client
      .get("contact")
      .then((response) => {
        const formattedData = response.data.map((item) => {
          item.key = item.id_destinatario;
          const dateObj = new Date(item.fechaNacimiento);

          const year = dateObj.getUTCFullYear();
          const month = String(dateObj.getUTCMonth() + 1).padStart(2, "0");
          const day = String(dateObj.getUTCDate()).padStart(2, "0");

          let formattedDate = `${year}-${month}-${day}`;
          if (formattedDate === "NaN-NaN-NaN") formattedDate = "";

          item.fechaNacimiento = formattedDate;
          return item;
        });

        setContacts(formattedData);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const downloadTemplate = () => {
    const link = document.createElement("a");
    link.href = "/templates/PlantillaContactos.xlsx"; // URL de la plantilla de Excel
    link.download = "PlantillaContactos.xlsx";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <div className="row mb-5">
        <div className="col">
          <h2 className="page-title">Lista de contactos</h2>
        </div>
        <div className="col-auto">
          <button
            type="button"
            className="btn btn-primary mr-2"
            onClick={showModal}
          >
            <span className="fe fe-upload fe-12 mr-2"></span>Importar Contactos
          </button>
          <button
            type="button"
            className="btn btn-primary mr-2"
            onClick={downloadTemplate}
          >
            <span className="fe fe-download fe-12 mr-2"></span>Plantilla
          </button>
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => navigate("/new-contact")}
          >
            <span className="fe fe-plus fe-12 mr-2"></span>Nuevo contacto
          </button>
        </div>
      </div>

      <div className="card shadow">
        <div className="card-body">
          <ContactListTable contacts={contacts} />
        </div>
      </div>

      <Modal
        title="Importar Contactos"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <DragAndDrop
          setIsModalVisible={setIsModalVisible}
          navigate={navigate}
          setContacts={setContacts}
        />
      </Modal>
    </>
  );
}
