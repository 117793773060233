import { gql } from "@apollo/client";

export const REGISTRY_IVR = gql`
  mutation registrar_ivr(
    $token: String!
    $nombre: String!
    $extension_ivr: String!
  ) {
    registrar_ivr(
      token: $token
      nombre: $nombre
      extension_ivr: $extension_ivr
    ) {
      estado
      data {
        nombre
        extension_ivr
        estado
      }
      error
    }
  }
`;
