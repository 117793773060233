import { useState } from 'react';
import {v4 as uuidv4} from 'uuid';

function NavSubItems(props) {
    const [show,setShow] = useState('');
    return(
        <li className="nav-item dropdown" >
            <a data-toggle="collapse" aria-expanded="true" className="dropdown-toggle nav-link" onClick={()=>{if(show===''){setShow('show')} else setShow('')}}>
                <i className="fe fe-credit-card fe-16"></i>
                <span className="ml-3 item-text" >{props.name}</span>
            </a>
            <ul className={show+" collapse list-unstyled pl-4 w-100 collapse"}>
                {
                    props.subItems
                }
            </ul>
        </li>
    )
}

export default NavSubItems