import { useEffect, useState } from 'react'
import './Grid.css'
import { v4 as uuidv4 } from 'uuid';
import swal from 'sweetalert';

function Grid(props) {

  const {destinatarios, setDestinatarios}=props

  /*useEffect(() => {
    setData(props.data)
  },[props.data])*/

  /*useEffect(() => {
    if (data != null) {
      props.callbackNumberList(data.map((item) => { return item.numero_celular }))
    }
  }, [data])*/
  return (
    <>
      {/*<div className="row items-align-center my-4  d-none d-lg-flex">
        <div className="col-md">
          <ul className="nav nav-pills justify-content-start">
            <li className="nav-item">
              <label className="nav-link active bg-transparent pr-2 pl-0 text-primary">Total destinatarios:<span className="badge badge-pill bg-primary text-white ml-2">{destinatarios?destinatarios.length:(0)}</span></label>
            </li>
            <li className="nav-item">
              <label className="nav-link active bg-transparent pr-2 pl-0 text-primary">Por Llamar:<span className="badge badge-pill bg-primary text-white ml-2">{props.remainingNumbersToCall?(props.remainingNumbersToCall.length):(0)}</span></label>
            </li>
          </ul>
        </div>
        <div className="col-md-auto ml-auto text-right">
          <button type="button" className="btn"><span className="fe fe-refresh-ccw fe-16 text-success"></span></button>
        </div>
  </div>*/}
      <div className="row">
        <div className="col-md-12">

          <table className="table table-borderless table-striped">
            <thead>
              <tr>
                <th><strong>Destinatario</strong></th>
                <th className="text-center"><strong>Número Destino</strong></th>
                <th className="text-center"><strong>Estado de la llamada</strong></th>
                <th className="text-center"><strong>Acciones</strong></th>
              </tr>
            </thead>
            <tbody>
              {
                destinatarios ? (destinatarios.map((item, index) => {
                  return (
                    <tr key={uuidv4()}>
                      <td>{item.nombre} {item.apellido}</td>
                      <td className="text-center">{item.numero_celular}</td>
                      <td className="text-center">{item.estado==1?(<span className="badge badge-pill badge-warning">Pendiente</span>):item.estado==="Llamada Realizada"?(<span className="badge badge-pill badge-success">Llamada Realizada</span>):(<span className="badge badge-pill badge-danger">{item.estado}</span>)}</td>
                      <td className={"text-center"}>
                        <button className="btn btn-danger "type="button" 
                          onClick={()=>{
                            swal({
                              title: "Se Eliminara El Número De La Lista",
                              text:  "¿Esta seguro de eliminar el número "+item.numero_celular+" de la lista?",
                              icon: "warning",
                              buttons: ["Cancelar","Eliminar"],
                            }).then((respuesta) => {
                              if (respuesta) {
                                props.callbackNumberList(item)
                              }
                            });
                          }}
                          >
                          <span className="">Eliminar</span>
                        </button>                        
                      </td>
                    </tr>
                  )
                })) : <></>
              }

            </tbody>
          </table>

        </div>
      </div>


    </>
  )




}

export default Grid