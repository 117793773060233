import React from "react";
import { InboxOutlined } from "@ant-design/icons";
import { message, Upload } from "antd";
import * as XLSX from "xlsx";
import createAxiosInstance from "../../utils/axios.config";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";

const { Dragger } = Upload;

export default function DragAndDrop({
  setIsModalVisible,
  navigate,
  setContacts,
}) {
  const client = createAxiosInstance();

  // Función Auxiliar para poner la fecha de Excel en formato MySQL
  function excelDateToJSDate(serial) {
    const utcDays = Math.floor(serial - 25569); // 25569 es la cantidad de días entre 1970/01/01 y 1900/01/01
    const utcValue = utcDays * 86400; // 86400 segundos en un día
    const dateInfo = new Date(utcValue * 1000);

    const year = dateInfo.getFullYear();
    const month = dateInfo.getMonth() + 1; // getMonth devuelve de 0 a 11, sumamos 1 para ajustar a 1 a 12
    const day = dateInfo.getDate();

    // Formateamos la fecha en un string "YYYY-MM-DD"
    const formattedDate = `${year}-${month.toString().padStart(2, "0")}-${day
      .toString()
      .padStart(2, "0")}`;

    return formattedDate;
  }

  const handleUploadContacts = async (list) => {
    const promises = list.map((contact) => {
      if (contact.nombre && contact.apellido && contact.numcelular) {
        return client.post("contact", {
          nombre: contact.nombre,
          apellido: contact.apellido,
          email: contact.email,
          numero_celular: contact.numcelular,
          fechaNacimiento: excelDateToJSDate(contact.fecha_nacimiento),
          genero: contact.genero,
        });
      } else {
        return Promise.reject({
          error: "Missing required fields",
          contact,
        });
      }
    });

    const results = await Promise.allSettled(promises);

    const failedContacts = results
      .filter((result) => result.status === "rejected")
      .map((result) => result.reason);

    if (failedContacts.length === 0) {
      swal("Registros con éxito", "", "success").then(() => {
        setIsModalVisible(false);
        client
          .get("contact")
          .then((response) => {
            const formattedData = response.data.map((item) => {
              item.key = item.id_destinatario;
              const dateObj = new Date(item.fechaNacimiento);

              const year = dateObj.getUTCFullYear();
              const month = String(dateObj.getUTCMonth() + 1).padStart(2, "0");
              const day = String(dateObj.getUTCDate()).padStart(2, "0");

              let formattedDate = `${year}-${month}-${day}`;
              if (formattedDate === "NaN-NaN-NaN") formattedDate = "";

              item.fechaNacimiento = formattedDate;
              return item;
            });

            setContacts(formattedData);
          })
          .catch((err) => {
            console.log(err);
          });
        navigate("/contact-list");
      });
    } else {
      const errorMessages = failedContacts
        .map(
          (failure) =>
            `Contact: ${JSON.stringify(failure.contact)}\nError: ${
              failure.error
            }`
        )
        .join("\n\n");
      swal("Error al crear algunos contactos", errorMessages, "error").then(
        () => {
          setIsModalVisible(false);
          client
            .get("contact")
            .then((response) => {
              const formattedData = response.data.map((item) => {
                item.key = item.id_destinatario;
                const dateObj = new Date(item.fechaNacimiento);

                const year = dateObj.getUTCFullYear();
                const month = String(dateObj.getUTCMonth() + 1).padStart(
                  2,
                  "0"
                );
                const day = String(dateObj.getUTCDate()).padStart(2, "0");

                let formattedDate = `${year}-${month}-${day}`;
                if (formattedDate === "NaN-NaN-NaN") formattedDate = "";

                item.fechaNacimiento = formattedDate;
                return item;
              });

              setContacts(formattedData);
            })
            .catch((err) => {
              console.log(err);
            });
          navigate("/contact-list");
        }
      );
    }
  };

  const handleFileUpload = async (file) => {
    if (!file) return;

    const reader = new FileReader();
    reader.onload = async (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet);

      console.log("Datos leídos:", jsonData);

      const newItems = [];

      jsonData.forEach((item) => {
        const newItem = {
          nombre: item.Nombre,
          apellido: item.Apellido,
          email: item.Email,
          numcelular: item["Numero Celular"],
          fecha_nacimiento: item["Fecha de Nacimiento"],
          genero: item.Genero,
        };
        newItems.push(newItem);
      });

      await new Promise((resolve) => setTimeout(resolve, 1000));

      handleUploadContacts(newItems);

      message.success(`${file.name} file uploaded and processed successfully.`);
    };
    reader.readAsArrayBuffer(file);
  };

  const props = {
    name: "file",
    multiple: true,
    accept: ".xlsx",
    customRequest({ file, onSuccess, onError }) {
      console.log("Files:", file);
      const reader = new FileReader();
      reader.onload = async (event) => {
        try {
          await handleFileUpload(file);
          onSuccess("ok");
        } catch (error) {
          onError(error);
          message.error(`${file.name} file upload failed.`);
        }
      };
      reader.readAsArrayBuffer(file);
    },
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  return (
    <Dragger {...props}>
      <p className="ant-upload-drag-icon">
        <InboxOutlined style={{ color: "green" }} />
      </p>
      <p className="ant-upload-text">
        Haz click o arrastra un archivo .xlsx a esta área para subir
      </p>
      <p className="ant-upload-hint">Soporta solo un archivo .xlsx.</p>
    </Dragger>
  );
}
